// export const chartColors=() => {
//     var colors=[
//         '#008FFB','#7B3F00','#FEB019','#FF4560','#3F51B5','#546E7A','#D4526E','#E59866','#F86624','#1B998B',
//         '#2E294E','#E2C044','#C068A8','#5c1b4c','#982754','#9f8500','#78710c','ff931f','#1F4E6C','#CC8899',
//         '#FD6C9E','#DA70D6','#CC7722','#000080','#E0B0FF','#800000','#00A86B','#FBCEB1','#89CFF0','#0095B6',
//         '#CD7F32','#702963','#007BA7','#6F4E37','#50C878'
//     ]
//     return colors;
// }

export const chartColors=() => {
    var colors=[
        '#7CD200','#FF8000','#00A6A4','#546E7A','#D4526E','#E59866','#008FFB','#F86624','#1B998B',
        '#2E294E','#E2C044','#C068A8','#5c1b4c','#982754','#00C1BF','#9f8500','#2CA01C','#78710c','ff931f','#1F4E6C','#CC8899',
        '#FD6C9E','#DA70D6','#CC7722','#000080','#E0B0FF','#FEB019','#800000','#00A86B','#FBCEB1','#89CFF0','#0095B6',
        '#CD7F32','#702963','#007BA7','#FF4560','#008380','#6F4E37','#3F51B5','#50C878','#7B3F00'
    ]
    return colors;
}